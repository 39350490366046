import { Typography } from "@mui/material"
import React from "react"
import { IntlProvider as ReactIntlProvider } from "react-intl"

// https://formatjs.io/docs/runtime-requirements

// Android is missing SG translation so force polyfill
require("@formatjs/intl-displaynames/polyfill-force")
require("@formatjs/intl-displaynames/locale-data/en")

// Firefox sucks
require("@formatjs/intl-numberformat/polyfill")
require("@formatjs/intl-numberformat/locale-data/en")

const onLocalizationError = (err: Error) => {
	const msg = err.message

	// remove stack trace
	const parts = msg.split("\n\n")
	console.error(parts[0])
}

// Silence warning about messages not being compiled
const dummyMessages = {
	_: [],
}

export const IntlProvider: React.FC = ({ children }) => (
	<ReactIntlProvider
		defaultLocale="en"
		defaultRichTextElements={{
			b: chunks => <b>{chunks}</b>,
			error: chunks => <Typography variant="caption">{chunks}</Typography>,
			info: chunks => <Typography variant="caption">{chunks}</Typography>,
			primary: chunks => <Typography variant="body1">{chunks}</Typography>,
			secondary: chunks => <Typography variant="body2">{chunks}</Typography>,
			success: chunks => <Typography variant="caption">{chunks}</Typography>,
			tertiary: chunks => <Typography variant="caption">{chunks}</Typography>,
			warning: chunks => <Typography variant="caption">{chunks}</Typography>,
		}}
		locale="en"
		messages={dummyMessages}
		onError={onLocalizationError}
		textComponent="span"
	>
		{children}
	</ReactIntlProvider>
)
