import { ThemeOptions } from "@mui/material"

export const webTheme: ThemeOptions = {
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontSize: "18px",
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				fullWidth: true,
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				input: {
					width: "auto",
				},
				select: {
					width: "auto",
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				fullWidth: true,
			},
		},
	},
	palette: {
		error: {
			contrastText: "#fff",
			dark: "var(--error-border)",
			light: "#f76f79",
			main: "#f76f79",
		},
		mode: "dark",
		primary: {
			contrastText: "#fff", // "var(--primary-text)",
			dark: "var(--primary-border)",
			light: "var(--primary-color)",
			main: "#4272fa", //"var(--primary-color)",
		},
		secondary: {
			contrastText: "#fff", // "var(--secondary-text)",
			dark: "var(--secondary-border)",
			light: "var(--secondary-color)",
			main: "#9823f2", // "var(--secondary-color)",
		},
	},
}
